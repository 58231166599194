<template>
  <footer class="footer">
    <div class="container-fluid">
      <nav>
        <ul class="footer-menu">
          <li>
            <router-link :to="{ path: '/login' }">Account</router-link>
          </li>
        </ul>
      </nav>
      <div class="copyright text-center">
        &copy; Created by
        <a href="https://www.icede.co.uk" target="_blank">iCede</a>.
      </div>
    </div>
  </footer>
</template>
<script>
export default {};
</script>
<style></style>
